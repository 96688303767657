import React from 'react';
import Menu from './Components/Menu';
import About from './Components/About';
import Choise from './Components/Choise';
import Service from './Components/Service';
import Client from './Components/Client';
import Bourse from './Components/Bourse';
import Exchange from './Components/Exchange';
import Map from './Components/Map';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <Menu />
      <About />
      <Choise />
      <Service />
      <Client />
      <Bourse />
      <Exchange />
      <Map />
      <FAQ />
      <Footer />
    </>
  )
}

export default App