import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const holders = [
  {
    id: 1,
    title: 'Закрывающие документы',
    href: '#',
  },
  {
    id: 2,
    title: 'Биржа бонусов',
    href: '#',
  },
]

const producers = [
  {
    id: 1,
    title: 'Биржа бонусов',
    href: '#',
  },
]

const Base_url = 'https://client.service-oem.ru/auth/register/foroemedia/login.php';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='bg-white pt-24 sm:pt-32'>
        <div className='mx-auto max-w-7xl px-6 hidden lg:px-8 lg:block'>
            <Box className='mx-auto block' sx={{ width: '100%' }}>
              <Box className='mx-auto' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs className='lg:mx-56' value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Держателям бизнес карт" {...a11yProps(0)} />
                  <Tab label="Торгово-сервисным предприятиям" {...a11yProps(1)} />
                  <Tab label="Производителям" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <div className='w-full block lg:flex lg:justify-between'>
                <CustomTabPanel className='w-full mt-7 text-white bg-sky-400 shadow-xl rounded-xl hover:bg-sky-500 lg:w-1/3 lg:ml-14' value={value} index={0}>
                  Закрывающие документы
                </CustomTabPanel>
                <CustomTabPanel className='w-full mt-7 text-white bg-sky-400 shadow-xl rounded-xl hover:bg-sky-500 lg:w-1/3 lg:mr-14' value={value} index={0}>
                  Биржа Бонусов
                </CustomTabPanel>
              </div>
              <div className='w-full block lg:flex lg:justify-between'>
                <CustomTabPanel className='w-full mt-7 text-white bg-sky-400 shadow-xl rounded-xl hover:bg-sky-500 lg:w-1/3 lg:ml-14' value={value} index={1}>
                  Закрывающие документы
                </CustomTabPanel>
                <CustomTabPanel className='w-full mt-7 text-white bg-sky-400 shadow-xl rounded-xl hover:bg-sky-500 lg:w-1/3 lg:mr-14' value={value} index={1}>
                  Биржа Бонусов
                </CustomTabPanel>
              </div>
              <div className='w-full block lg:flex lg:justify-center'>
                <CustomTabPanel className='w-full mt-7 text-white bg-sky-400 shadow-xl rounded-xl hover:bg-sky-500 lg:w-1/3' value={value} index={2}>
                  Биржа Бонусов
                </CustomTabPanel>
              </div>
            </Box>
        </div>
        <div className="mx-auto max-w-7xl px-6 hidden">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl mb-5 font-bold tracking-tight text-gray-900 sm:text-4xl">Держателям бизнес карт</h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-0 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {holders.map((holder) => (
              <article key={holder.id} className="flex max-w-xl h-40 mb-7 flex-col items-start justify-between bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600">
                <div className="group relative">
                  <h3 className="text-3xl mt-7 font-semibold leading-6 text-center text-white group-hover:text-gray-600">
                    <a href={holder.href}>
                      <span className="absolute text-center mx-auto inset-0" />
                      {holder.title}
                    </a>
                  </h3>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 hidden">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl mb-5 font-bold tracking-tight text-gray-900 sm:text-4xl">Торгово-сервисным предприятиям</h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-0 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {holders.map((holder) => (
              <article key={holder.id} className="flex max-w-xl h-40 mb-7 flex-col items-start justify-between bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600">
                <div className="group relative">
                  <h3 className="text-3xl mt-7 font-semibold leading-6 text-center text-white group-hover:text-gray-600">
                    <a href={holder.href}>
                      <span className="absolute text-center mx-auto inset-0" />
                      {holder.title}
                    </a>
                  </h3>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 hidden">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl mb-5 font-bold tracking-tight text-gray-900 sm:text-4xl">Производителям</h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-0 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {producers.map((producer) => (
              <article key={producer.id} className="flex max-w-xl h-40 mb-7 flex-col items-start justify-between bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600">
                <div className="group relative">
                  <h3 className="text-3xl mt-7 font-semibold leading-6 text-center text-white group-hover:text-gray-600">
                    <a href={producer.href}>
                      <span className="absolute text-center mx-auto inset-0" />
                      {producer.title}
                    </a>
                  </h3>
                </div>
              </article>
            ))}
          </div>
        </div>
    </div>
  );
}