import { BanknotesIcon, DocumentCheckIcon, HandRaisedIcon, ScaleIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Предоставление электронных документов',
    description:
      '(в т.ч. электронных счетов-фактур) подтверждающих факт совершения покупки товара/услуги корпоративным клиентом на Партнерской сети с использованием бизнес-карты и оформленных в соответствии с ст. 169 (НК РФ) для возмещения НДС',
    icon: DocumentCheckIcon,
  },
  {
    name: 'Исполнение требований Федерального закона',
    description:
      'от 22.05.2003 г. № 54-ФЗ «О применении контрольно-кассовой техники при осуществлении наличных денежных расчетов и (или) расчетов с использованием платежных карт».',
    icon: ScaleIcon,
  },
  {
    name: 'Снижение операционных расходов',
    description:
      'OEM-Service берет на себя функцию создания Цифровых кошельков под торговой маркой Партнёра или Партнёра Производителя, эмиссии Цифровых Бонусов, подключение действующей клиентской базы, предоставление доступа и технической возможности для начисление и списания на партнёрской сети ОЕМ Цифровых Бонусов от Партнёра Производителя',
    icon: BanknotesIcon,
  },
  {
    name: 'Соответствие требований Законодательству',
    description:
      'Исполнение требований Федерального закона от 22.05.2003 г. № 54-ФЗ «О применении контрольно-кассовой техники при осуществлении наличных денежных расчетов и (или) расчетов с использованием платежных карт»',
    icon: HandRaisedIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-white sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            О сервисе 
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Сервис по предоставлению полностью систематизированного электронного документооборота для держателей Бизнес-карт
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
