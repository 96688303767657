const posts = [
    {
      id: 1,
      title: '1',
      href: '#',
      description:
        'Совместные маркетинговые мероприятия с участием Банков Партнёров, Торговыми сетями, сетями АЗС, Спортивными Федерациями, Благотворительными фондами',
    },
    {
        id: 2,
        title: '2',
        href: '#',
        description:
          'Доступ к самой большой клиентской аудитории',
      },
      {
        id: 3,
        title: '3',
        href: '#',
        description:
          'Прямое влияние на покупательскую активность',
      },
      {
        id: 4,
        title: '4',
        href: '#',
        description:
          'Возможность таргетинга на свою Целевую аудиторию',
      },
      {
        id: 5,
        title: '5',
        href: '#',
        description:
          'Увеличение клиентской базы за счет потока клиентов – держателей банковских карт Банков Партнёров',
      },
      {
        id: 6,
        title: '6',
        href: '#',
        description:
          'Повышение узнаваемости Бренда',
      },
  ]

  const Base_url = 'https://client.service-oem.ru/auth/register/foroemedia/login.php';
  
  export default function Example() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Преимущества для клиента</h2>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex max-w-xl h-56 flex-col items-start justify-between bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600">
                <div className="group relative">
                  <h3 className="text-3xl mt-7 font-semibold leading-6 text-center text-white group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 ml-11 mr-11 pb-11 line-clamp-3 text-sm leading-6 text-slate-50">{post.description}</p>
                </div>
              </article>
            ))}
          </div>
          <div className="btn-connect text-center">
            <button className="w-44 mt-11 px-7 py-3 text-white bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-xl hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600"><a href={Base_url}>Присоединиться</a></button>
          </div>
        </div>
      </div>
    )
  }
  