import { React, useState } from 'react';
import axios from 'axios';
import { EnvelopeIcon, HomeIcon, PaperAirplaneIcon, PhoneArrowDownLeftIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'

export default function Example() {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
    const [agreed, setAgreed] = useState(false)


      const [formData, setFormData] = useState({
        name: '',
        last: '',
        organization: '',
        number: '',
        email: '',
        message: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
          // Проверка обязательных полей
          if (!formData.name || !formData.last || !formData.organization || !formData.email) {
            alert('Пожалуйста, заполните все обязательные поля.');
            return;
          }
        
          // Проверка корректности email
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(formData.email)) {
            alert('Пожалуйста, введите корректный адрес электронной почты.');
            return;
          }

            // Проверка активации Switch
          if (!agreed) {
            alert('Пожалуйста, примите условия конфиденциальности.');
            return;
          }
        
          const formDataToSend = {
            name: formData.name,
            last: formData.last,
            organization: formData.organization,
            number: formData.number,
            email: formData.email,
            message: formData.message,
            agreed: agreed // включаем agreed в данные формы для отправки
          };

        try {
          await axios.post('http://localhost:4000/send-email', formDataToSend);
          alert('Сообщение успешно отправлено!');
        } catch (error) {
          console.error('Ошибка отправки сообщения:', error);
          alert('Произошла ошибка при отправке сообщения.');
        }
      };

    return (
        <div className="mx-auto max-w-7xl px-0 lg:px-0">
            <div className="mx-auto lg:mx-0">
            <div className="bg-gray-900 w-full h-auto mt-20 lg:mt-32">
                <div className="w-full h-auto lg:pt-20">
                    <div className="block w-full h-auto border-t border-gray-500 lg:flex lg:justify-between">
                        <div className="w-full lg:w-1/3 lg:mx-7 lg:my-32">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-7 text-white">Контакты</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-white">Краткая информация о сервисе</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-white">Потребителям</dt>
                                        <dt className="text-sm font-medium leading-6 text-white">Бизнесу</dt>
                                    </div>
                                    <div className="px-4 py-6 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-white">Клиентам</dt>
                                        <dt className="text-sm font-medium leading-6 text-white">Держателям бизнес карт</dt>
                                    </div>
                                    <div className="px-4 py-6 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-white">Фондам</dt>
                                        <dt className="text-sm font-medium leading-6 text-white">Производителям</dt>
                                    </div>
                                    <div className="px-4 py-6 flex justify-between sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-white">Спортивным федерациям</dt>
                                        <dt className="text-sm font-medium leading-6 text-white">Торгово-сервисным предприятиям</dt>
                                    </div>
                                </dl>
                            </div>
                            <div className="w-full flex justify-between border-t border-gray-100 lg:mx-0">
                                <div className="w-1/12 mt-7 ml-5 inline-block lg:mt-7">
                                    <HomeIcon className="w-6 h-6 text-white text-left my-auto lg:mx-auto" aria-hidden="true" />
                                    <PhoneArrowDownLeftIcon className="w-6 h-6 mt-14 text-white text-left my-auto lg:mt-14" aria-hidden="true" />
                                    <EnvelopeIcon className="w-6 h-6 mt-11 text-white text-left my-auto lg:mt-11" aria-hidden="true" />
                                    <PaperAirplaneIcon className="w-6 h-6 mt-11 text-white text-left my-auto lg:mt-11" aria-hidden="true" />
                                </div>
                                <div className="w-64">
                                    <p className="w-64 h-auto mt-7 text-left text-white text-sm leading-6">Москва, Переулок Сивцев Вражек, 25/9с1</p>                                   
                                    <p className="w-64 h-auto text-left text-left mt-7 text-white text-sm leading-6">+7 (800) 775 82 44</p>                                  
                                    <p className="w-64 h-auto text-left mt-11 text-left text-white text-sm leading-6">j.lysenko@oe-media.ru</p>                          
                                    <p className="w-64 h-auto text-left mt-11 text-left text-white text-sm leading-6">@OEMCryptoBonusBot</p>
                                </div>
                        </div> 
                        </div>
                        <div className="isolate w-full bg-gray-900 px-6 py-11 sm:py-32 lg:px-8 lg:w-2/3">
                            <div className="mx-auto max-w-2xl text-center">
                              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Обратная связь</h2>
                              <p className="mt-2 text-lg leading-8 text-gray-600">
                              Любой вопрой или предложение можно оставить здесь. Мы ответим в ближайшее время
                              </p>
                            </div>
                            <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={handleSubmit}>
                              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                                    Ваше имя
                                  </label>
                                  <div className="mt-2.5">
                                    <input
                                      type="text"
                                      name="first-name"
                                      id="first-name"
                                      autoComplete="given-name"
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      value={formData.name}
                                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                                    Ваша фамилия
                                  </label>
                                  <div className="mt-2.5">
                                    <input
                                      type="text"
                                      name="last-name"
                                      id="last-name"
                                      autoComplete="family-name"
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      value={formData.last}
                                      onChange={(e) => setFormData({ ...formData, last: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-2">
                                  <label htmlFor="company" className="block text-sm font-semibold leading-6 text-white">
                                    Организация
                                  </label>
                                  <div className="mt-2.5">
                                    <input
                                      type="text"
                                      name="company"
                                      id="company"
                                      autoComplete="organization"
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      value={formData.organization}
                                      onChange={(e) => setFormData({ ...formData, organization: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-2">
                                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                                    Email
                                  </label>
                                  <div className="mt-2.5">
                                    <input
                                      type="email"
                                      name="email"
                                      id="email"
                                      autoComplete="email"
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      value={formData.email}
                                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-2">
                                  <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-white">
                                    Контактный телефон
                                  </label>
                                  <div className="relative mt-2.5">
                                    <input
                                      type="tel"
                                      name="phone-number"
                                      id="phone-number"
                                      autoComplete="tel"
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      defaultValue={''}
                                      value={formData.number}
                                      onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-2">
                                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                    Сообщение
                                  </label>
                                  <div className="mt-2.5">
                                    <textarea
                                      name="message"
                                      id="message"
                                      rows={4}
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      defaultValue={''}
                                      value={formData.message}
                                      onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                                  <div className="flex h-6 items-center">
                                    <Switch
                                      checked={agreed}
                                      onChange={setAgreed}
                                      className={classNames(
                                        agreed ? 'bg-indigo-600' : 'bg-gray-200',
                                        'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                      )}
                                    >
                                      <span className="sr-only">Agree to policies</span>
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          agreed ? 'translate-x-3.5' : 'translate-x-0',
                                          'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                        )}
                                      />
                                    </Switch>
                                  </div>
                                  <Switch.Label className="text-sm leading-6 text-gray-600">
                                  Выбирая это, вы соглашаетесь с нашей{' '}
                                    <a href="#" className="font-semibold text-indigo-600">
                                    политикой&nbsp;конфиденциальности
                                    </a>
                                    .
                                  </Switch.Label>
                                </Switch.Group>
                              </div>
                              <div className="mt-10">
                                <button
                                  type="submit"
                                  className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Отправить
                                </button>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div> 

    )
  }
