import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionUsage() {
  return (
    <div className="bg-white pt-20 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Что такое закрывающие документы по совершенным операциям для корпоративных клиентов?<br/>
            </AccordionSummary>
            <AccordionDetails>
              Это сервис, позволяющий автоматически формировать закрывающие документы по операциям, совершенным по бизнес-карте на Партнерской сети ОЕМ:<br />
              Участвуйте в проекте и получайте преимущества:<br />
              - Доступ к широчайшей сети корпоративных клиентов крупнейших банков страны<br />
              - Минимизация затрат на маркетинговые мероприятия;<br />
              - Предоставление современного сервиса клиентам;<br />
              - Исполнение требований Федерального закона от 22.05.2003 №54-ФЗ.О применении контрольно-кассовой техники при осуществлении наличных денежных расчетов и (или) расчетов с использованием платежных карт.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Как подключиться к Партнерской сети?
            </AccordionSummary>
            <AccordionDetails>
              Заполнить заявку на сайте.<br />
              Связаться с нами напрямую по номеру 8 (800) 550-27-40.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Каким способом осуществляется направление Бухгалтерских документов в адрес клиентов?
            </AccordionSummary>
            <AccordionDetails>
              Первичные бухгалтерские документы будут автоматически сформированы и направлены в Банк-клиент корпоративного клиента.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Как клиент, использующий Банковскую карту, узнает о возможности обслуживания на Партнерской сети?
            </AccordionSummary>
            <AccordionDetails>
              Информирование корпоративных клиентов о возможности обслуживания на Вашей сети будет осуществляться посредством смс-рассылок и push-уведомления.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}